
import Vue from "vue";

import { authMapActions } from "@/store/modules/auth";
import { appMapGetters } from "@/store/modules/app";
import { get } from "lodash";
import { validateFields } from "@/helpers";
import { appBaseTheme } from "@/helpers/appCoreBaseSettings";
import { license } from "@/forms/shared/license";
import PasswordField from "@/components/User/Password.vue";
import { agencyMapActions, agencyMapState } from "@/store/modules/agency";
import * as selectOptions from "@/helpers/selectOptions";
import { logoPath } from "@/helpers/generalHelpers";

interface ILoginData {
  registering: boolean;
  error: string;
  userInfo: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: Record<string, any>;
    agencyCode?: string;
    adjusterCode?: string;
    adjustingFirm: string;
  };
  loading: boolean;
  emailInUse: boolean;
  checkingEmail: boolean;
  passwordIsValid: boolean;
  agencyCodeIsValid: boolean;
  hasValidRegistrationData: boolean;
  isAdjuster: boolean;
  adjustingFirmOptions: any;
  adjusterFirmFocused: boolean;
}

export default Vue.extend({
  name: "register",
  components: { PasswordField },
  data(): ILoginData {
    return {
      registering: false,
      loading: false,
      error: "",
      userInfo: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phone: { primary: "", primaryVerified: false },
        agencyCode: "",
        adjusterCode: "",
        adjustingFirm: ""
      },
      emailInUse: false,
      checkingEmail: false,
      passwordIsValid: false,
      agencyCodeIsValid: true,
      hasValidRegistrationData: false,
      isAdjuster: false,
      adjustingFirmOptions: [],
      adjusterFirmFocused: false
    };
  },
  mounted() {
    const adjusterParam = this.$route.params.userType;
    this.isAdjuster = adjusterParam === "adjuster";
    //temporary fix
    // const registerMain = document.querySelector(".register--main") as any;
    // if (registerMain.parentNode)
    //   registerMain.parentNode.classList.remove("wrap");
  },
  methods: {
    ...authMapActions(["register", "checkEmailInUse"]),
    ...agencyMapActions(["verifyAgencyCode"]),
    getLogoPath() {
      return logoPath();
    },
    async signUp(): Promise<void> {
      const timer = this.__gtagMeasureTiming("register");
      this.error = "";
      this.registering = true;
      const loading = this.$loading({
        lock: true
      });
      try {
        if (this.hasValidRegistrationData) {
          this.loading = true;
          await this.register({
            ...this.userInfo,
            isAdjuster: this.isAdjuster
          });
          this.$router.push("/register/welcome").catch(() => {});
        } else {
          this.error = "Correct all errors in form";
          this.$appNotifyError(this.error);
        }
      } catch (e) {
        this.error = e.message;
        this.$appNotifyError(this.error);
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        loading.close();
        this.registering = false;
        timer.done();
      }
    },
    async checkEmailAlreadyInUse() {
      this.checkingEmail = true;
      const { email } = this.userInfo;
      try {
        const { emailInUse } = await this.checkEmailInUse(email);
        this.emailInUse = emailInUse;
      } catch (error) {
        this.emailInUse = false;
        this.$bugSnagClient.notify(error);
      } finally {
        this.checkingEmail = false;
      }
    },
    async checkAgencyCodeValidity() {
      if (this.userInfo.agencyCode) {
        try {
          const response = await this.verifyAgencyCode(
            this.userInfo.agencyCode
          );
          const { agencyCodeIsValid } = response.data;
          this.agencyCodeIsValid = agencyCodeIsValid;
          this.validateForm();
        } catch (error) {
          this.agencyCodeIsValid = false;
          this.$bugSnagClient.notify(error);
        }
      }
    },
    customEmailValidator(): boolean {
      return !this.emailInUse;
    },
    customAgencyValidator(): boolean {
      return this.agencyCodeIsValid;
    },
    passwordChangeHandler(payload: {
      isValid: boolean;
      password: string;
    }): void {
      this.passwordIsValid = payload.isValid;
      this.userInfo.password = payload.password;
    },
    formFieldChangeHandler(payload: { key: any; value: any }) {
      const { value, key } = payload;
      Vue.set(this.userInfo, key, value);
    },
    licenseFieldIsValid(): boolean {
      const res =
        !!get(this.userInfo, "licenseNumber") &&
        !!get(this.userInfo, "licenseNumberExpirationDate");
      return res;
    },
    validateForm() {
      this.hasValidRegistrationData =
        validateFields(this.$refs) &&
        this.passwordIsValid &&
        (this.licenseFieldIsValid() || this.isAdjuster) &&
        (this.agencyCodeIsValid || this.isAdjuster) &&
        (this.adjustingFirmValid || !this.isAdjuster);
    },
    filterAdjusterFirms(query: any, cb: any) {
      if (query !== "") {
        this.loading = true;
        this.adjustingFirmOptions = selectOptions.fieldAdjustingFirmsOptions.filter(
          (item: any) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          }
        );
        cb(this.adjustingFirmOptions);
        this.loading = false;
      } else {
        this.adjustingFirmOptions = [];
      }
    },
    handleSelectAdjustingFirm(item: any) {
      this.userInfo.adjustingFirm = item.label;
    },
    setAdjusterFirm(value: string) {
      const match: any = selectOptions.adjusterFirms.filter(
        (option: any) => option["<ExamininerCD>"] === value.toUpperCase()
      )[0];
      if (match) {
        this.userInfo.adjustingFirm = `${match["Field Adjuster"]}`;
      }
    }
  },
  computed: {
    ...appMapGetters(["getAppViewSettings", "getAppTheme"]),
    ...agencyMapState(["makingApiRequest"]),
    appLogo(): string {
      const { logoUrl } = this.getAppTheme;
      return logoUrl;
    },
    pageBackground(): string {
      const { views } = this.getAppTheme;
      const { login } = views;
      const image = login.backgroundImage
        ? login.backgroundImage
        : appBaseTheme.views.login.backgroundImage;
      return image;
    },
    getLicenseFormBuilderSchema(): any {
      return license(!this.isAdjuster);
    },
    adjustingFirmValid(): boolean {
      const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      return (
        !!this.userInfo.adjustingFirm &&
        this.userInfo.adjustingFirm.length >= 3 &&
        !specialChars.test(this.userInfo.adjustingFirm)
      );
    }
  },
  watch: {
    userInfo: {
      handler() {
        this.validateForm();
      },
      deep: true
    }
  }
});
